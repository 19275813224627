<div>
  <mat-toolbar 
    [ngClass]="{
      'h-76 flex bg-cartwheelBlack': isLgScreen$ | async,
      'p-0 h-65 max-h-65': !(isLgScreen$ |async)
      }">
    @if (currentRole$ | async) {
      <mat-toolbar-row
        class="h-full p-0"
        [ngClass]="{
          'justify-between': !(isLgScreen$ | async)
        }">
        <a [routerLink]="['/Home/']">
          <img src="../../assets/Logo Mark White Transparent.svg"
            [ngClass]="{
              'h-42 w-42 mr-35 ml-25': isLgScreen$ | async,
              'ml-12 h-33 w-33': !(isLgScreen$ | async)
            }">
        </a>
        @if (isLgScreen$ | async) {
          <ul class="flex-1 min-w-540">
            @for (menuItem of menuItems; track 'menu-item-li' + menuItem.routerlink) {
              @if (menuItem.userRoles.includes((currentRole$ | async)?.userRole)) {
                <li class="float-left min-w-90 desktop:min-w-100">
                  <a
                    routerLinkActive="active"
                    class="leading-[64px] block text-white text-center px-10 py-6 m-auto text-20 opacity-80 font-normal"
                    [routerLinkActiveOptions]="{exact: true}"
                    [routerLink]="[menuItem.routerlink]"
                    [attr.data-cy]="menuItem.data_cy">
                    {{menuItem.label}}
                  </a>
                </li>
              }
            }
          </ul>
          <cartwheel-header-notification 
            [notifications]="(notifications$ | async)" 
            (readNotification)="readNotification($event)"
          />
          @if (roles) {
            <button
              disableRipple
              mat-button
              class="team-selector"
              [matMenuTriggerFor]="menu">
              <div class="company-name">{{ (currentRole$ | async)?.companyName }}</div>
              <small>{{ UserRole[(currentRole$ | async)?.userRole] }}</small>
              <mat-icon  class="-translate-y-1/2 top-1/2">keyboard_arrow_down</mat-icon>
            </button>
          }
          <mat-menu class="min-w-300" disableRipple #menu>
            <button disableRipple mat-menu-item matIconSuffix class="mt-10 px-18 min-w-250" (click)="logout()">
              <div class="flex justify-between font-bold">
                <p class="text-15 text-darkerGray leading-22">Log out</p>
                <mat-icon class="mr-0 text-darkerGray text-[22px] w-22 h-22">output</mat-icon>
              </div>
            </button>
            <cartwheel-role-select [roles]="roles" [role]="currentRole$ | async" (roleChange)="changeRole($event)"/>
          </mat-menu>
        } @else {
          <mat-select
            disableRipple
            class="text-center text-white border-none w-350 top-17 opacity-80"
            panelClass="header-mobile-menu"
            [hideSingleSelectionIndicator]="true"
            [value]="currentRoute$ | async"
            (selectionChange)="changeRouter($event)">
            @for (menuItem of menuItems; track 'menu-item-option' + menuItem.routerlink) {
              @if (menuItem.userRoles.includes((currentRole$ | async)?.userRole)) {
                <mat-option [value]="menuItem.routerlink">
                    {{menuItem.label}}
                </mat-option>
              }
            }
          </mat-select>
          <button
            disableRipple
            mat-button
            #menuTrigger="matMenuTrigger"
            class="opacity-60"
            [disabled]="!roles?.length"
            [matMenuTriggerFor]="mobileMenu">
            <mat-icon class="w-24 h-24 text-white text-26">{{menuTrigger.menuOpen ? 'close' : 'menu'}}</mat-icon>
          </button>
          <mat-menu class="w-auto h-auto" disableRipple #mobileMenu>
            <button disableRipple mat-menu-item (click)="openTeamSelectorDialog()">
              <div class="company-name">{{ (currentRole$ | async)?.companyName }}</div>
              <small class="company-role">{{ UserRole[(currentRole$ | async)?.userRole] }}</small>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </mat-menu>
        }
      </mat-toolbar-row>
    }
  </mat-toolbar>
  @if (isMobile$ | async) {
    <footer class="fixed z-[1000] bottom-0 w-full h-[71px] bg-white border-t border-opacity-10">
      <app-timeclock class="block" />
    </footer>
  }
</div>
@if (!(isMobile$ | async)) {
  <app-timeclock class="block mdl-layout--large-screen-only" />
}
@if ((currentRole$ | async)?.userRole === UserRole.User && (currentRoute$ | async).indexOf('/ftux') < 0) {
  <app-alerts />
}
